import styled from "styled-components/macro"

import { pxToRem } from "../../../utils"
import Container from "../../common/Container"

export const Medium = styled.span`
  font-weight: 500;
`
export const Strong = styled.span`
  font-weight: 700;
`
export const Smaller = styled.span`
  font-size: ${pxToRem(22)};
`
export const Hero = styled.section`
  position: relative;
  text-align: center;

  & ${Container} {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 40vh ${pxToRem(15)} ${pxToRem(60)} ${pxToRem(15)};
  }

  @media all and (min-width: 1024px) {
    height: calc(90vh - 85px);
    & ${Container} {
      padding: 0 ${pxToRem(15)} ${pxToRem(160 + 60)} ${pxToRem(15)};
    }
  }
`
export const Pattern = styled.div`
  display: none;
  background-image: ${props => (props.bg ? `url(${props.bg})` : "")};
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  width: 50%;
  height: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;

  &.reverse {
    right: 0;
    left: initial;
    transform: scaleX(-1);
  }

  @media all and (min-width: 821px) {
    display: block;
    width: 30%;
  }

  @media all and (min-width: 1281px) {
    display: block;
    width: 50%;
  }
`
export const HeroTitle = styled.h1`
  font-family: "Teko", sans-serif;
  font-size: ${pxToRem(80)};
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: ${pxToRem(20)};
  line-height: 0.85;

  @media all and (min-width: 821px) {
    font-size: ${pxToRem(110)};
  }
`
export const HeroAltTitle = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(30)};
  font-weight: 700;
  margin: 0;
  line-height: 1.2;

  @media all and (min-width: 821px) {
    font-size: ${pxToRem(40)};
  }
`
export const VideoBackground = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -100;
  top: 0;
  left: 0;
`
export const Video = styled.video`
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
`
export const Accent = styled.span`
  color: #b7ff12;
`
export const HeroBox = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  max-width: 900px;
  padding: ${pxToRem(15)};
  /* background: linear-gradient(
    45deg,
    rgba(196, 255, 69, 1) 0%,
    rgba(179, 255, 0, 1) 100%,
    rgba(179, 255, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#C4FF45',endColorstr='#B3FF00' , GradientType=1); */
  background-color: #e0f993;
  color: #0f283a;

  @media all and (min-width: 821px) {
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 320px;
    transform: translate(-50%, 50%);
    padding: ${pxToRem(30)};
  }
`
export const HeroBoxTitle = styled.h3`
  font-family: "Montserrat", sans-serif;
  color: #0f283a;
  font-size: ${pxToRem(25)};
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-top: 0;
  margin-left: ${pxToRem(-15)};
  margin-right: ${pxToRem(-15)};
  margin-bottom: 10px;
  background: #b7ff12;

  @media all and (min-width: 821px) {
    font-size: ${pxToRem(30)};
    letter-spacing: 10px;
    margin-left: ${pxToRem(-30)};
    margin-right: ${pxToRem(-30)};
  }
`
export const HeroBoxTriangle = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  max-width: 900px;
  width: 100%;
  height: 320px;
  transform: translate(-50%, 50%);
  max-width: 883px;
  /* max-width: initial; */
  height: 310px;
  /* z-index: 10; */
  background-size: contain;
  left: calc(50% - 30px);
  bottom: -21px;
  background-position: center;

  background-image: ${props => (props.bg ? `url(${props.bg})` : "")};
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;

  @media all and (min-width: 1024px) {
    display: block;
  }
`
export const HeroBoxDescription = styled.p`
  color: #0f283a;
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(20)};
  font-weight: 400;
  font-style: italic;
  margin: 0;
  margin-bottom: ${pxToRem(5)};

  @media all and (min-width: 821px) {
    margin-bottom: 0;
  }
`
export const HeroBoxStrong = styled.p`
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(30)};
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: ${pxToRem(5)};

  @media all and (min-width: 821px) {
    font-size: ${pxToRem(40)};
    margin-bottom: 0;
  }
`
export const Belief = styled.section`
  padding: ${pxToRem(70)} 0;
  background-image: ${props => (props.bg ? `url(${props.bg})` : "")};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  & ${Container} {
    max-width: 1200px;

    @media all and (min-width: 1530px) {
      max-width: 1530px;
    }
  }

  @media all and (min-width: 821px) {
    padding: ${pxToRem(230)} 0 ${pxToRem(100)} 0;
  }
`
export const BeliefGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  grid-column-gap: ${pxToRem(100)};
  grid-row-gap: ${pxToRem(30)};

  @media all and (min-width: 821px) {
    grid-template-columns: 1fr 1fr;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const BeleifDescription = styled.p`
  font-style: italic;
  font-weight: 100;
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(30)};
  line-height: 1.4;
  margin-bottom: ${pxToRem(50)};

  @media all and (min-width: 821px) {
    font-size: ${pxToRem(40)};
  }
`
export const BeliefAuthor = styled.p`
  color: #c3ff42;
  font-style: italic;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(30)};
`
export const Choose = styled.section`
  padding: ${pxToRem(70)} 0 0 0;
  margin-bottom: -${pxToRem(0)};
  color: #0f283a;
  background: linear-gradient(0deg, #fff 50%, #c4ff45 50%);
  overflow: hidden;

  & ${Container} {
    max-width: 1200px;

    @media all and (min-width: 1620px) {
      max-width: 1620px;
    }
  }

  @media all and (min-width: 821px) {
    margin-bottom: -${pxToRem(90)};
  }
`
export const ChooseGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${pxToRem(160)};
  grid-row-gap: ${pxToRem(30)};

  ${Column} {
    display: block;
    justify-content: initial;
    align-items: initial;
    padding-bottom: ${pxToRem(0)};
  }

  @media all and (min-width: 821px) {
    grid-template-columns: 1fr 1fr;

    ${Column} {
      padding-bottom: ${pxToRem(50)};
    }
  }
`
export const ChooseAltTitle = styled.h3`
  font-style: italic;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(30)};
  text-transform: uppercase;
  line-height: 1;
  margin-top: 0;
  margin-bottom: ${pxToRem(20)};

  @media all and (min-width: 821px) {
    font-size: ${pxToRem(40)};
    margin-bottom: ${pxToRem(20)};
  }
`
export const ChooseTitle = styled.h2`
  font-style: italic;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(50)};
  line-height: 0.9;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: ${pxToRem(30)};

  @media all and (min-width: 821px) {
    font-size: ${pxToRem(60)};
    line-height: initial;
  }
`
export const ChooseNormalDescription = styled.p`
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(28)};
  line-height: 2;
  margin-bottom: 0;
`
export const VideoIframe = styled.iframe`
  width: 100%;
  max-width: 960px;
  display: block;
  border: none;
  height: 470px;
  margin: 0 auto;
`
export const MediaContainer = styled.div`
  position: relative;
  margin-bottom: ${pxToRem(235 + 50)};

  ${VideoIframe} {
    position: absolute;
    left: 0;
    bottom: -205px;
  }

  @media all and (min-width: 821px) {
    ${VideoIframe} {
      bottom: -235px;
    }
  }
`
export const ChooseUppercaseDescription = styled.p`
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(28)};
  line-height: 2;
  margin-bottom: ${pxToRem(70)};
  padding-top: ${pxToRem(0)};

  @media all and (min-width: 821px) {
    text-transform: uppercase;
    font-size: ${pxToRem(24)};
    padding-top: ${pxToRem(70)};
    line-height: 2.5;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 6px;
  }
`
export const TakeALook = styled.section`
  padding: ${pxToRem(90)} 0 ${pxToRem(130)} 0;
  background-color: #191b1e;
  background-image: ${props => (props.bg ? `url(${props.bg})` : "")};
  background-repeat: no-repeat;

  & ${Container} {
    max-width: 1200px;

    @media all and (min-width: 1620px) {
      max-width: 1620px;
    }
  }

  @media all and (min-width: 821px) {
    grid-template-columns: 1fr 1fr;
    background-position: ${pxToRem(0)} ${pxToRem(90 + 50)};
  }

  @media all and (min-width: 1921px) {
    grid-template-columns: 1fr 1fr;
    background-position: 15% 25%;
  }
`
export const TakeALookGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${pxToRem(100)};
  grid-row-gap: ${pxToRem(30)};

  ${Column} {
    align-items: initial;

    ${VideoIframe} {
      margin-top: ${pxToRem(0)};
      height: 510px;
    }
  }

  @media all and (min-width: 821px) {
    grid-template-columns: 1fr 1fr;

    ${Column} {
      align-items: initial;

      ${VideoIframe} {
        margin-top: ${pxToRem(130)};
        height: 510px;
      }
    }
  }
`
export const TakeALookTitle = styled.h2`
  font-style: italic;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(60)};
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: ${pxToRem(50)};
  color: #b7ff12;
  line-height: 0.9;

  @media all and (min-width: 821px) {
    margin-bottom: ${pxToRem(120)};
  }
`
export const TakeALookDescription = styled.p`
  font-style: italic;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(28)};
  line-height: 2;
`
export const LookBoxTriangle = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  max-width: 900px;
  width: 100%;
  height: 320px;
  transform: translate(-50%, 50%);
  max-width: 883px;
  /* max-width: initial; */
  height: 310px;
  /* z-index: 10; */
  background-size: contain;
  left: calc(50% - 30px);
  bottom: -21px;
  background-position: center;

  background-image: ${props => (props.bg ? `url(${props.bg})` : "")};
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;

  @media all and (min-width: 1024px) {
    display: block;
  }
`
export const BoldCTA = styled.section`
  position: relative;
  padding-top: ${pxToRem(25)};
  background: linear-gradient(0deg, #2b4d5e 20%, #0f283a 0%);
`
export const BoldCTAGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  /* grid-column-gap: ${pxToRem(50)}; */

  ${Column} {
    align-items: initial;

    ${VideoIframe} {
      margin-top: ${pxToRem(130)};
      height: 510px;
    }
  }

  @media all and (min-width: 1025px) {
    grid-template-columns: 1fr 1fr;
  }
`
export const BoldCTATitle = styled.h2`
  font-style: italic;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(100)};
  text-transform: uppercase;
  margin-top: ${pxToRem(10)};
  margin-bottom: 0;
  color: #b7ff12;
  line-height: 0.71;
  letter-spacing: -4px;
  align-self: end;
  order: 2;

  @media all and (min-width: 1025px) {
    order: 1;
    font-size: ${pxToRem(175)};
    margin-top: 0;
  }
`
export const BoldCTADescription = styled.p`
  font-style: italic;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(20)};

  @media all and (min-width: 821px) {
    font-size: ${pxToRem(26)};
  }
`
export const BoldCTAButton = styled.a`
  display: inline-block;
  max-width: 350px;
  padding: ${pxToRem(10)} ${pxToRem(40)};
  background-color: #b7ff12;
  border-radius: 20px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(26)};
  text-transform: uppercase;
  color: #0f283a;
  text-align: center;
  margin-bottom: ${pxToRem(20)};

  @media all and (min-width: 821px) {
    font-size: ${pxToRem(36)};
  }
`
export const BoldCTADescriptionContainer = styled.div`
  order: 1;
  text-align: center;

  @media all and (min-width: 1025px) {
    order: 2;
  }
`
export const Portfolio = styled.section`
  padding: ${pxToRem(75)} 0 ${pxToRem(35)} 0;
  color: #191b1e;
  background-image: ${props => (props.bg ? `url(${props.bg})` : "")};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  & ${Container} {
    max-width: 1200px;

    @media all and (min-width: 1620px) {
      max-width: 1620px;
    }
  }
`
export const PortfolioGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${pxToRem(50)};
  grid-row-gap: ${pxToRem(30)};
  margin-bottom: ${pxToRem(50)};

  ${Column} {
    align-items: initial;
  }

  @media all and (min-width: 821px) {
    grid-template-columns: 1fr 1.8fr;
  }
`
export const PortfolioTitle = styled.h2`
  font-style: italic;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(50)};
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 0.9;

  @media all and (min-width: 768px) {
    font-size: ${pxToRem(60)};
  }
`
export const PortfolioDescription = styled.p`
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(28)};
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 0;

  @media all and (min-width: 821px) {
    font-size: ${pxToRem(20)};
    text-transform: uppercase;
  }
`
export const PortfolioGalleryLabel = styled.h3`
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(30)};
  text-transform: uppercase;
  margin-bottom: 0;
  text-align: center;
  line-height: 1;
`
export const Contact = styled.section`
  padding: ${pxToRem(100)} 0 ${pxToRem(100)} 0;
  background-color: #000;
  /* background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) -90%,
    rgba(0, 0, 0, 1) 20%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#000000' , GradientType=1); */
`
export const ContactTitle = styled.h2`
  font-weight: 600;
  font-size: ${pxToRem(60)};
  font-family: "Teko", sans-serif;
  color: #c4ff45;
  text-align: center;
  margin-top: 0;
  margin-bottom: ${pxToRem(40)};
  line-height: 0.9;
`
export const ContactDescription = styled.p`
  font-weight: 300;
  font-size: ${pxToRem(24)};
  text-align: center;

  &:last-child {
    margin-bottom: ${pxToRem(60)};
  }

  @media all and (min-width: 1025px) {
    font-size: ${pxToRem(28)};
  }
`
export const AltTag = styled.a`
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  font-style: italic;
`
