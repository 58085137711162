import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/common/Container"
import { useTailorAssets } from "../hooks/tailor"
import * as Styled from "../components/styles/pages/tailor-made-show.style"
import { pxToRem } from "../utils"
import ContactForm from "../components/ContactForm"
import Slider from "../components/Slider"
import TailorPopUp from "../components/TailorPopUp"

const TailorMadeShowPage = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleIsOpen = () => setIsOpen(!isOpen)
  const {
    tailorBg,
    tailorVideo,
    beliefBg,
    yettel,
    portfolioBg,
    heroPattern,
    heroBoxTriangle,
  } = useTailorAssets()
  return (
    <Layout page="tailor">
      <Styled.Hero>
        <Styled.Pattern bg={heroPattern} />
        <Container>
          <Styled.HeroTitle className="wow animate__fadeIn">
            <Styled.Accent>Tailor-Made</Styled.Accent> Show
          </Styled.HeroTitle>
          <Styled.HeroAltTitle
            className="wow animate__fadeIn"
            data-wow-delay=".2s"
          >
            Our ultra premium service is perfectly suited for each client's{" "}
            <Styled.Accent>unique and specific needs.</Styled.Accent>
          </Styled.HeroAltTitle>
        </Container>
        <Styled.VideoBackground>
          <Styled.Video
            autoPlay
            muted
            loop
            playsInline
            poster={tailorBg}
            preload="metadata"
          >
            <source src={tailorVideo} type="video/mp4" />
          </Styled.Video>
        </Styled.VideoBackground>
        <Styled.HeroBox>
          <Styled.HeroBoxTitle className="wow animate__fadeIn">
            The tailor-made show is
          </Styled.HeroBoxTitle>
          <Styled.HeroBoxStrong
            className="wow animate__fadeIn"
            data-wow-delay=".2s"
          >
            the perfect choice
          </Styled.HeroBoxStrong>
          <Styled.HeroBoxDescription
            className="wow animate__fadeIn"
            data-wow-delay=".3s"
          >
            if you are looking for a truly unique, ultimate high-end performance
            for an
          </Styled.HeroBoxDescription>
          <Styled.HeroBoxStrong
            className="wow animate__fadeIn"
            data-wow-delay=".4s"
          >
            exceptional event,
          </Styled.HeroBoxStrong>
          <Styled.HeroBoxDescription
            className="wow animate__fadeIn"
            data-wow-delay=".5s"
          >
            where extreme sport and dance elements are combined to achieve
          </Styled.HeroBoxDescription>
          <Styled.HeroBoxStrong
            className="wow animate__fadeIn"
            data-wow-delay=".6s"
          >
            maximum synergy.
          </Styled.HeroBoxStrong>
        </Styled.HeroBox>
        <Styled.HeroBoxTriangle bg={heroBoxTriangle} />
        <Styled.Pattern className="reverse" bg={heroPattern} />
      </Styled.Hero>
      <Styled.Belief bg={beliefBg}>
        <Container>
          <Styled.BeliefGrid>
            <Styled.Column>
              <Styled.BeleifDescription className="wow animate__fadeIn">
                We believe that the most prestigious events require a{" "}
                <Styled.Medium>monumental show</Styled.Medium> that perfectly
                fits the theme, vibe and atmosphere to reflect and represent the
                message of the occasion itself in the most exact way possible.
              </Styled.BeleifDescription>
              <Styled.BeliefAuthor
                className="wow animate__fadeIn"
                data-wow-delay=".2s"
              >
                David Balogh, <Styled.Smaller>CEO</Styled.Smaller>
              </Styled.BeliefAuthor>
            </Styled.Column>
            <Styled.Column>
              <StaticImage
                src="../images/Asset 33@3x.png"
                alt="tailor-made-show-showcase"
                className="wow animate__fadeIn"
                data-wow-delay=".2s"
              />
            </Styled.Column>
          </Styled.BeliefGrid>
        </Container>
      </Styled.Belief>
      <Styled.Choose>
        <Container>
          <Styled.ChooseGrid>
            <Styled.Column>
              <Styled.ChooseAltTitle className="wow animate__fadeIn">
                Why choose our
              </Styled.ChooseAltTitle>
              <Styled.ChooseTitle
                className="wow animate__fadeIn"
                data-wow-delay=".2s"
              >
                Tailor-made show
              </Styled.ChooseTitle>
              <Styled.MediaContainer>
                <StaticImage
                  src="../images/Asset 35@2x-80.jpg"
                  alt="yettel show"
                  style={{
                    marginLeft: "-100%",
                    marginRight: -80,
                    maxHeight: 680,
                  }}
                  className="wow animate__fadeIn"
                  data-wow-delay=".3s"
                />
                <Styled.VideoIframe
                  loading="lazy"
                  data-src="https://www.youtube-nocookie.com/embed/2TZDHr5a3QQ"
                  src={`https://www.youtube-nocookie.com/embed/2TZDHr5a3QQ`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowFullScreen
                  className="wow animate__fadeIn lazyload"
                  data-wow-delay=".3s"
                />
              </Styled.MediaContainer>
              <Styled.ChooseNormalDescription
                className="wow animate__fadeIn"
                data-wow-delay=".2s"
              >
                Our network consists of over 120 professional athletes, and
                additional pro dancers, stylists, make-up artists, DJs and all
                the key experts to design a unique and extra super show. One
                production can include up to 50 performers, which guarantees a
                monumental performance.
              </Styled.ChooseNormalDescription>
            </Styled.Column>
            <Styled.Column>
              <Styled.ChooseUppercaseDescription className="wow animate__fadeIn">
                With the help of acclaimed professional choreographers, costume
                stylists, DJs and other professionals we compose the performance
                elements in such a way that they are in perfect harmony with all
                the other parts of the show. From matching colors and music
                through LED wall animations to custom made podiums, we have all
                the infrastructure to support the planning of everything to
                create a perfectly cohesive performance and synesthesia on stage
                to spark all your senses and to make the client's vision come
                true.
              </Styled.ChooseUppercaseDescription>
              <StaticImage
                src="../images/Asset 36@2x-80.jpg"
                alt="yettel show"
                style={{
                  marginRight: "-100%",
                  marginLeft: -80,
                  maxHeight: 535,
                }}
                className="wow animate__fadeIn"
                data-wow-delay=".2s"
              />
            </Styled.Column>
          </Styled.ChooseGrid>
        </Container>
      </Styled.Choose>
      <Styled.TakeALook bg={yettel}>
        <Container>
          <Styled.TakeALookGrid>
            <Styled.Column>
              <Styled.TakeALookTitle className="wow animate__fadeIn">
                Take a look!
              </Styled.TakeALookTitle>
              <Styled.TakeALookDescription
                className="wow animate__fadeIn"
                data-wow-delay=".2s"
              >
                Watch our tailor-made show from the Yettel rebranding
                announcement event that represented the change and
                transformation in the life of the telecommunication company.
                This professional video truly reflects the atmosphere of the
                performance!
              </Styled.TakeALookDescription>
            </Styled.Column>
            <Styled.Column style={{ position: "relative" }}>
              <Styled.VideoIframe
                loading="lazy"
                data-src="https://www.youtube-nocookie.com/embed/7Vt0S_RhJAM"
                src={`https://www.youtube-nocookie.com/embed/7Vt0S_RhJAM`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowFullScreen
                className="wow animate__fadeIn lazyload"
                data-wow-delay=".2s"
              />
              {/* <Styled.LookBoxTriangle bg={lookBoxTriangle} /> */}
            </Styled.Column>
          </Styled.TakeALookGrid>
        </Container>
      </Styled.TakeALook>
      <Styled.BoldCTA>
        <Styled.BoldCTAGrid>
          <Styled.BoldCTATitle className="wow animate__fadeIn">
            Extra!
          </Styled.BoldCTATitle>
          <Styled.BoldCTADescriptionContainer>
            <Styled.BoldCTADescription
              className="wow animate__fadeIn"
              data-wow-delay=".2s"
            >
              If you want to see the behind the scenes video of the Yettel show
              then
            </Styled.BoldCTADescription>
            <Styled.BoldCTAButton
              className="wow animate__fadeIn"
              data-wow-delay=".3s"
              onClick={toggleIsOpen}
            >
              Click here!
            </Styled.BoldCTAButton>
          </Styled.BoldCTADescriptionContainer>
        </Styled.BoldCTAGrid>
      </Styled.BoldCTA>
      <Styled.Portfolio bg={portfolioBg}>
        <Container>
          <Styled.PortfolioGrid>
            <Styled.PortfolioTitle className="wow animate__fadeIn">
              Our Portfolio
            </Styled.PortfolioTitle>
            <Styled.PortfolioDescription
              className="wow animate__fadeIn"
              data-wow-delay=".2s"
            >
              With over 120 professionals and over 25 different types of extreme
              sports, it is guaranteed that we have a selection of performers to
              suit your event. In our portfolio you can find all sorts of acts
              to create the most extreme combinations: tightrope walker at 80m
              high, incredible contortionist hand balancer or a breathtaking LED
              lightshow.
            </Styled.PortfolioDescription>
          </Styled.PortfolioGrid>
          <Slider />
        </Container>
      </Styled.Portfolio>
      <Styled.Contact id="contact">
        <Container>
          <Styled.ContactTitle className="wow animate__fadeIn">
            We are ready to design the most CAPTIVATING <br /> and one-of-a-kind
            performance for your event.
          </Styled.ContactTitle>
          <Styled.ContactDescription
            style={{ marginBottom: `${pxToRem(60)}` }}
            className="wow animate__fadeIn"
            data-wow-delay=".2s"
          >
            <Styled.Strong>Fill the form</Styled.Strong> below or message us at{" "}
            <Styled.AltTag href="mailto:show@flipunitprod.com">
              show@flipunitprod.com
            </Styled.AltTag>
          </Styled.ContactDescription>
          <ContactForm
            formName="Tailor made fix"
            buttonColor="#c4ff45"
            video={false}
            mailchimpTags={["Tailor made oldali contact formon iratkozott fel"]}
            webhookURL="https://flipunitprod-en.netlify.app/.netlify/functions/tailor"
          />
        </Container>
      </Styled.Contact>
      <TailorPopUp onClose={toggleIsOpen} open={isOpen} />
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="TAILOR-MADE SHOW | Custom extreme sport show for your event"
    slug="/tailor-made-show"
    description="Custom extreme sports megashow curated for your event: professionals choregraphers, dancers, a selection of pro extreme sport athletes and experienced managers."
  />
)

export default TailorMadeShowPage
