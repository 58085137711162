import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState, useEffect } from "react"
import { useSwipeable } from "react-swipeable"
import styled from "styled-components/macro"

import IconLeft from "../images/icon-left.svg"
import IconRight from "../images/icon-right.svg"
import * as Styled from "./styles/pages/tailor-made-show.style"
import { useSliderAssets } from "../hooks/slider"

const SliderContainer = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  margin-bottom: 2.77777778rem;
`
const Image = styled(GatsbyImage)``
const Slide = styled.div`
  display: grid;
  min-width: 100%;
  min-height: 200px;
  justify-items: center;
  ${props =>
    props.position ? `transform: translateX(${props.position}%)` : ""};
  transition: transform 0.5s ease;
  will-change: transform;
  grid-template-columns: repeat(1, calc(100% / 1));

  & ${Image} {
    width: 100%;
  }
`
const Pagination = styled.div`
  position: absolute;
  bottom: 100px;
  left: 40px;
  right: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & svg {
    margin: 0 10px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
    .cls-1 {
      fill: none;
    }
  }
`
const IconContainer = styled.div`
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Slider = () => {
  const [visibleId, setVisibleId] = useState(0)
  const slides = useSliderAssets()
  const maxLength = slides.length

  useEffect(() => {
    const interval = setInterval(() => next(), 8000)
    return function cleanup() {
      clearInterval(interval)
    }
  })

  const prev = () => {
    if (visibleId - 1 < 0) {
      setVisibleId(maxLength - 1)
    } else {
      setVisibleId(visibleId - 1)
    }
  }
  const next = () => {
    if (visibleId + 1 >= maxLength) {
      setVisibleId(0)
    } else {
      setVisibleId(visibleId + 1)
    }
  }
  const handlers = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
    trackTouch: true,
    trackMouse: true,
  })
  return (
    <SliderContainer {...handlers}>
      {slides.map((slide, idx) => (
        <Slide key={idx} position={visibleId * -100}>
          <Image
            image={getImage(slide.src)}
            key={idx}
            alt={slide.alt}
            title={slide.alt}
          />
          <Styled.PortfolioGalleryLabel>
            {slide.alt}
          </Styled.PortfolioGalleryLabel>
        </Slide>
      ))}
      <Pagination className="wow fadeIn">
        <IconContainer onClick={prev}>
          <IconLeft />
        </IconContainer>
        <IconContainer onClick={next}>
          <IconRight />
        </IconContainer>
      </Pagination>
    </SliderContainer>
  )
}

export default Slider
