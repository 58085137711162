import { useStaticQuery, graphql } from "gatsby"

export const useSliderAssets = () => {
  const data = useStaticQuery(
    graphql`
      query SliderImages {
        slides: allFile(
          filter: {
            absolutePath: { regex: "/images/tailor//" }
            extension: { ne: "mp4" }
          }
          sort: { fields: name }
        ) {
          edges {
            slide: node {
              name
              src: childImageSharp {
                gatsbyImageData(formats: [JPG], width: 1560)
              }
            }
          }
        }
      }
    `
  )

  return data.slides.edges.map(node => ({
    src: node.slide.src.gatsbyImageData,
    alt: node.slide.name.split("__")[1].split(".")[0].replaceAll("-", " "),
  }))
}
