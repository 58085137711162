import { useStaticQuery, graphql } from "gatsby"

export const useTailorAssets = () => {
  const images = useStaticQuery(
    graphql`
      query TailorImages {
        tailorBg: file(relativePath: { eq: "airtrack-hero-video.jpg" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [JPG])
          }
        }
        heroPattern: file(relativePath: { eq: "Asset 32@3x.png" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [PNG])
          }
        }
        heroBoxTriangle: file(
          relativePath: { eq: "taylor rectangle 1@2x-8.png" }
        ) {
          src: childImageSharp {
            gatsbyImageData(formats: [PNG])
          }
        }
        lookBoxTriangle: file(
          relativePath: { eq: "taylor rectangle 2@2x-8.png" }
        ) {
          src: childImageSharp {
            gatsbyImageData(formats: [PNG])
          }
        }
        tailorVideo: file(relativePath: { eq: "tailormade_bg.mp4" }) {
          publicURL
        }
        beliefBg: file(relativePath: { eq: "belief-bg@2x-80.jpg" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [JPG])
          }
        }
        yettel: file(relativePath: { eq: "yettel.png" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [PNG])
          }
        }
        portfolioBg: file(relativePath: { eq: "Asset 40@2x-80.jpg" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [JPG])
          }
        }
        watchImage: file(relativePath: { eq: "img-see-the-show.png" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [PNG])
          }
        }
        watchImageHover: file(
          relativePath: { eq: "img-see-the-show__hoover.png" }
        ) {
          src: childImageSharp {
            gatsbyImageData(formats: [PNG])
          }
        }
        yettelPopup: file(relativePath: { eq: "Asset 35@2x-80.jpg" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [JPG])
          }
        }
      }
    `
  )

  return {
    tailorBg: images.tailorBg.src.gatsbyImageData.images.fallback.src,
    tailorVideo: images.tailorVideo.publicURL,
    beliefBg: images.beliefBg.src.gatsbyImageData.images.fallback.src,
    yettel: images.yettel.src.gatsbyImageData.images.fallback.src,
    portfolioBg: images.portfolioBg.src.gatsbyImageData.images.fallback.src,
    watchImage: images.watchImage.src.gatsbyImageData.images.fallback.src,
    watchImageHover:
      images.watchImageHover.src.gatsbyImageData.images.fallback.src,
    heroPattern: images.heroPattern.src.gatsbyImageData.images.fallback.src,
    heroBoxTriangle:
      images.heroBoxTriangle.src.gatsbyImageData.images.fallback.src,
    lookBoxTriangle:
      images.lookBoxTriangle.src.gatsbyImageData.images.fallback.src,
    yettelPopup: images.yettelPopup.src.gatsbyImageData.images.fallback.src,
  }
}
