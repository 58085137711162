import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components/macro"
import { useTailorAssets } from "../hooks/tailor"
import { encode, pxToRem } from "../utils"

function validate({ email, terms }, setError) {
  return new Promise((resolve, reject) => {
    const emailRegexp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    let errors = {}
    if (!email) {
      errors = { ...errors, email: "Email is required!" }
    } else if (!emailRegexp.test(email)) {
      errors = { ...errors, email: "Email format is invalid!" }
    }
    if (!terms)
      errors = {
        ...errors,
        terms: "You must accept the terms and the privacy policy!",
      }
    setError(errors)
    Object.keys(errors).length === 0 ? resolve() : reject()
  })
}

const TailorPopUp = ({
  formName = "Tailor pop-up",
  buttonColor,
  onClose,
  open = false,
}) => {
  const [state, setState] = useState({})
  const [error, setError] = useState({})

  const { yettelPopup } = useTailorAssets()

  const handleChange = async e => {
    const newState = { ...state, [e.target.name]: e.target.value }
    setState(newState)
    validate(newState, setError).catch(() => {})
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const form = e.target
    validate(state, setError)
      .then(() => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": form.getAttribute("name"),
            ...state,
          }),
        })
          .then(() => onClose())
          .catch(error => alert(error))
      })
      .catch(error => {})
  }

  return (
    <>
      <Modal open={open}>
        <ModalGrid>
          <ImageColumn bg={yettelPopup} />
          <Content>
            <CloseButton onClick={onClose} />
            <FormTitle>Hi!</FormTitle>
            <FormDescription>
              To watch the behind-the-scenes video of Yettel show just type in
              your e-mail address and we are going to send you the link to the
              video!
            </FormDescription>
            <Form
              name={formName}
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              <TextInput
                type="hidden"
                name="form-name"
                value={formName}
                onChange={handleChange}
              />
              <div style={{ marginBottom: 30 }}>
                <TextInput
                  type="text"
                  name="email"
                  placeholder="Email *"
                  onChange={handleChange}
                />
                {error.email && <Error>{error.email}</Error>}
              </div>
              <div
                style={{
                  marginBottom: 30,
                  marginLeft: 15,
                }}
              >
                <Label htmlFor="terms">
                  <Checkbox
                    id="terms"
                    type="checkbox"
                    name="terms"
                    onChange={handleChange}
                  />
                  I agree to the
                  <StyledLink to="/terms-and-conditions">
                    {" "}
                    terms and conditions{" "}
                  </StyledLink>{" "}
                  and
                  <StyledLink to="/privacy-policy">
                    {" "}
                    privacy policy.{" "}
                  </StyledLink>
                </Label>
                {error.terms && <Error>{error.terms}</Error>}
              </div>
              <Button
                color={buttonColor}
                type="submit"
                disabled={Object.keys(error).length > 0}
              >
                Watch the video
              </Button>
            </Form>
          </Content>
        </ModalGrid>
      </Modal>
      <Overlay onClick={onClose} open={open} />
    </>
  )
}

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  visibility: ${props => (props.open ? "visible" : "hidden")};
  opacity: ${props => (props.open ? 1 : 0)};
  transition: all 0.3s ease-in-out;
`
const Modal = styled.div`
  color: #000;
  max-width: 340px;
  max-height: 642px;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: linear-gradient(-45deg, #e1f793 50%, #c4ff45 50%);
  visibility: ${props => (props.open ? "visible" : "hidden")};
  opacity: ${props => (props.open ? 1 : 0)};
  transition: all 0.3s ease-in-out;

  @media all and (min-width: 1024px) {
    max-width: 1200px;
  }
`
const ModalGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 120px 1fr;
  grid-gap: 0;
  height: 100%;

  @media all and (min-width: 1024px) {
    grid-template-columns: 30% 1fr;
    grid-template-rows: 1fr;
  }
`
const ImageColumn = styled.div`
  background-image: ${props => (props.bg ? `url(${props.bg})` : "")};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`
const Content = styled.div`
  padding: ${pxToRem(20)};
  position: relative;
  overflow: auto;

  @media all and (min-width: 1024px) {
    padding: ${pxToRem(50)};
  }
`
const FormTitle = styled.h2`
  margin: 0;
  font-style: italic;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(50)};

  @media all and (min-width: 1024px) {
    font-size: ${pxToRem(100)};
  }
`
const FormDescription = styled.p`
  font-style: italic;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(20)};
  line-height: 1.3;
  margin-bottom: ${pxToRem(15)};

  @media all and (min-width: 1024px) {
    font-size: ${pxToRem(28)};
  }
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
`
const TextInput = styled.input`
  width: 100%;
  color: #000;
  border: 1px solid #a9a9a9;
  background-color: #fff;
  font-size: ${pxToRem(18)};
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  padding: 0.5rem 1rem;
  margin-bottom: 0;

  &::placeholder {
    color: #a9a9a9;
    font-weight: 300;
  }

  &:focus {
    outline: none;
  }

  @media all and (min-width: 1024px) {
    font-size: ${pxToRem(22)};
    padding: 1rem 2rem;
  }
`
const Label = styled.label`
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  color: #000;
  display: block;
  align-items: center;

  @media all and (min-width: 1024px) {
    display: flex;
    align-items: center;
  }
`
const Checkbox = styled.input`
  width: 1rem;
  height: 1rem;
  margin: 0;
  margin-right: 1rem;
`
const Button = styled.button`
  align-self: center;
  text-transform: uppercase;
  text-decoration: none;
  font-size: ${pxToRem(25)};
  line-height: 0.9;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #c4ff44;
  padding: ${pxToRem(12)} ${pxToRem(20)};
  border-radius: ${pxToRem(40)};
  border: none;
  outline: none;
  cursor: pointer;
  max-width: 400px;
  justify-self: center;
  height: 50px;
  background-color: #0f283a;
  transition: color 0.3s ease-in-out;

  &:disabled,
  &[disabled] {
    background-color: #0f283a;
    cursor: not-allowed;

    &:hover {
      color: #c4ff44;
    }
  }
  &:hover {
    color: #fff;
  }

  @media all and (min-width: 1024px) {
    max-width: 400px;
    font-size: ${pxToRem(30)};
  }
`
const Error = styled.span`
  display: inline-block;
  margin-top: ${pxToRem(5)};
  opacity: 0.6;
  font-weight: 300;
`
const StyledLink = styled(Link)`
  font-weight: 700;
  text-decoration: none;
  color: #000;
  display: inline-block;
  margin: 0 ${pxToRem(5)};
`
const CloseButton = styled.div`
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
  border-radius: 50%;
  position: absolute;
  top: ${pxToRem(20)};
  right: ${pxToRem(20)};
  background-color: #0f283a;
  cursor: pointer;

  &:after,
  &:before {
    content: "";
    background-color: #c4ff44;
    border-radius: 1px;
    height: 1px;
    width: 25px;
    position: absolute;
    top: 50%;
    left: 3px;
    transition: background-color 0.3s ease-in-out;

    @media all and (min-width: 768px) {
      left: 8px;
    }
  }

  &:after {
    transform: rotate(45deg);
  }
  &:before {
    transform: rotate(-45deg);
  }

  &:hover {
    &:after,
    &:before {
      background-color: #fff;
    }
  }
`

export default TailorPopUp
